<template>
  <div
    :class="[
      'callout component',
      variant,
      state,
      { 'no-border': noBorder, pointer: !!onClick },
    ]"
    :style="borderColor ? { borderColor } : undefined"
    @click="handleClick"
    :role="onClick ? 'button' : undefined"
    :data-i="icon"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useTrack } from '@/composables/plugins';
import { propsDefinition } from './CalloutProps';

const track = useTrack();
const props = defineProps(propsDefinition);

const trackClick = () => {
  if (!props.tid) return;
  track('card-click', { tid: props.tid });
};

const handleClick = (event: Event) => {
  if (!props.onClick) return;
  trackClick();
  props.onClick(event);
};
</script>

<template>
  <div
    :class="[$style.toggleWrapper, $style[variant]]"
    :data-disabled="disabled"
  >
    <label
      :class="{
        [$style.toggleOption]: true,
        [$style.checked]: pickedLeft,
        [$style.disabled]: disabled,
      }"
    >
      <div>
        <input
          type="radio"
          :value="props.firstOption.value"
          v-model="picked"
          style="display: none"
          :disabled="disabled"
          @blur="$emit('blur', $event)"
          @input="setValue($event)"
        />
        <template v-if="props.firstOption.icon">
          <Icon :icon="props.firstOption.icon" />
        </template>
        {{ props.firstOption.label }}
      </div>
    </label>

    <label
      :class="{
        [$style.toggleOption]: true,
        [$style.checked]: pickedRight,
        [$style.disabled]: disabled,
      }"
    >
      <div>
        <input
          type="radio"
          :value="props.secondOption.value"
          v-model="picked"
          style="display: none"
          :disabled="disabled"
          @blur="$emit('blur', $event)"
          @input="setValue($event)"
        />
      </div>
      <template v-if="props.secondOption.icon">
        <Icon :icon="props.secondOption.icon" />
      </template>
      {{ props.secondOption.label }}
    </label>
  </div>
</template>
<script setup lang="ts">
import { ref, watch, computed } from 'vue';

type Option = {
  label: string;
  value: unknown;
  icon?: string;
};

type Props = {
  firstOption: Option;
  secondOption: Option;
  value: unknown;
  disabled?: boolean;
  variant?: 'default' | 'gray';
};

const emit = defineEmits(['blur', 'input']);

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  variant: 'default',
});
const picked = ref<typeof props.value>();
const pickedLeft = computed(() => picked.value === props.firstOption.value);
const pickedRight = computed(() => picked.value === props.secondOption.value);

watch(
  () => props.value,
  (newValue) => {
    picked.value = newValue;
  },
  { immediate: true }
);

function setValue(event: Event) {
  const target = event.target as HTMLInputElement;
  const newPicked =
    target.value === 'true'
      ? true
      : target.value === 'false'
      ? false
      : target.value;
  picked.value = newPicked;
  emit('input', newPicked);
}
</script>
<style module>
.toggleOption {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  color: var(--gray-9);
  user-select: none;

  &:hover {
    background-color: var(--gray-1);
  }
}

.toggleWrapper {
  display: flex;
  flex-shrink: 0;
  height: 100%;
  line-height: 1;
  border: 1px solid var(--gray-5);
  border-radius: 2em;
  overflow: hidden;

  & > :first-child {
    padding-left: 1.3em;
    border-right: 1px solid var(--gray-5);
  }
  & > :last-child {
    padding-right: 1.3em;
  }

  > label {
    padding: 0.5em 1em;
    cursor: pointer;
  }

  &[data-disabled='true'] {
    --color-hover: var(--gray-1);

    > label {
      cursor: default;
    }
  }
}

.default {
  --color-hover: var(--gold-1);

  .toggleOption.checked {
    background-color: var(--color-signal);
    color: inherit;

    &.disabled {
      background-color: var(--gold-2);
    }
  }
}

.gray {
  --color-hover: var(--gray-1);

  .toggleOption.checked {
    background-color: var(--gray-8);
    color: var(--color-white);

    &.disabled {
      background-color: var(--gray-6);
    }
  }
}
</style>

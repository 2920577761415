<template>
  <div
    v-tooltip="tooltip"
    :class="['badge', variant, { 'icon-only': !hasLabel }, { pill }]"
    @click="$emit('click', $event)"
    :data-i="icon"
  >
    <slot>{{ label }}</slot>
  </div>
</template>

<script lang="ts">
export const variants = [
  'default',
  'border',
  'primary',
  'secondary',
  'purple',
  'success',
  'warning',
  'signal',
  'alert',
  'pill',
  'new',
] as const;
</script>

<script setup lang="ts">
import { computed, useSlots, withDefaults } from 'vue';

export type Props = {
  variant?: (typeof variants)[number];
  icon?: string;
  label?: string;
  pill?: boolean;
  tooltip?: string | Record<string, unknown>;
};
const props = withDefaults(defineProps<Props>(), { variant: undefined });

const slots = useSlots();
const slotText = computed(() => (slots.default?.()[0]?.text || '').trim());
const hasLabel = computed(() => Boolean(slotText.value || props.label));
</script>
